/*Home.css*/

/* Restaurant Header */
/*.restaurant-header {*/
/*    text-align: left;*/
/*}*/

/* Ensure logo and name are in the same row */
.restaurant-title-container {
    display: flex;
    align-items: center; /* Align logo and name in the same row */
    /*gap: 5px; !* Space between logo and name *!*/
}

/* Restaurant Logo */
.restaurant-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

/* Ensure title and logo are aligned */
.restaurant-header {
    /*display: flex;*/
    /*align-items: center;*/
    text-align: left;
    gap: 10px; /* Space between logo and text */
    width: 100%;
}


/* Restaurant Title */
.restaurant-title {
    font-size: 32px !important;
    font-weight: bold;
    color: #333;
    display: inline-block; /* Keep it in line with the logo */

    margin-bottom: 5px;
}

/* Address with Icon */
.restaurant-address {
    font-size: 14px;
    color: #555;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Address Icon */
.restaurant-icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

/* Buttons Row */
.restaurant-buttons {
    display: flex;
    justify-content: flex-start;
    gap: 5%;
    margin-left: 0;
    margin-right: 0;
    /*width: 100%;*/
    margin-bottom: 5px;
}

/* Buttons - Icons Left, Text Centered */
.restaurant-button {
    display: flex;
    align-items: center;
    justify-content: center; /* Ensure text remains centered */
    padding: 8px; /* Adjusted padding */
    font-size: 16px;
    font-weight: bold;
    border-radius: 8px;
    text-decoration: none;
    color: black;
    /*background-color: white;*/
    border: 1px solid black;
    width: 42%; /* Ensures uniform size */
    /*height: 20px; !* Reduced height *!*/
    gap: 5%; /* Space between icon and text */
    transition: all 0.2s ease-in-out;
}

/* Button Icons - Now properly spaced */
.button-icon {
    width: 18px;
    height: 18px;
    flex-shrink: 0; /* Prevents the icon from shrinking */
}

/* Ensure button text stays properly aligned */
.restaurant-button span {
    flex-grow: 1;
    text-align: center;
}


/* Button Hover Effect */
.restaurant-button:hover {
    background-color: #f8f8f8;
    transform: scale(1.05);
}

/* RESPONSIVE DESIGN */
@media (max-width: 1000px) {
    .restaurant-title {
        font-size: 20px !important; /* Smaller title on mobile */
    }

    /*.restaurant-buttons {*/
    /*    flex-direction: row; !* Stack buttons *!*/
    /*    align-items: center;*/
    /*}*/

    /*.restaurant-button {*/
    /*    font-size: 14px;*/
    /*}*/

    .button-icon {
        width: 18px;
        height: 18px;
        left: 10px;
    }

    .restaurant-address {
        font-size: 12px;
    }

    .restaurant-icon {
        width: 18px;
        height: 18px;
    }

    .restaurant-logo {
        width: 35px;
        height: 35px;
    }


    .restaurant-buttons {
        display: flex;
        flex-wrap: wrap; /* Allow wrapping */
        justify-content: center; /* Center horizontally */
        /*gap: 10px; !* Space between buttons *!*/
        width: 100%;
    }

    .restaurant-button {
        /*flex: 0 1 calc(50% - 50px); !* Two buttons per row *!*/
        /*max-width: 150px; !* Ensure a consistent size *!*/
        /*text-align: center;*/
    }

    .restaurant-menu-mobile {
        display: flex;
        justify-content: center; /* Center the menu button */
        /*width: 100%;*/
        margin-top: 10px;
    }

    .restaurant-menu-button {
        width: 94%; /* Ensure consistent width */
        /*max-width: 300px; !* Limit max width *!*/
        justify-content: center;
        display: flex;
        gap: 8px;
        /*padding: 10px;*/
        font-size: 16px;
        font-weight: bold;
        border-radius: 8px;
        /*margin-left: 0;*/
        /*margin-right: 0;*/
    }
}


.home-container {
    padding: 20px;
    background-color: #efeeec;
    max-width: 50%;
    margin: 0 auto;
    min-height: calc(100vh - 100px);
}


/* Home Header */
.home-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Left-align content */
    position: relative;
}

.home-header-top {
    display: flex;
    justify-content: space-between; /* Aligns the solt logo to the left */
    width: 100%;
    margin-bottom: 10px;
    margin-top: 5px;
    align-items: center;
}

/* Join as Creator Button */
.join-creator-button {
    padding: 8px 16px;
    font-size: 14px;
    /*background-color: #000;*/
    /*color: #fff;*/
    /*border: none;*/
    /*border-radius: 8px;*/
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;

    background-color: #f0f0f0;
    color: #000000;
    border: 1px solid #666;
    border-radius: 15px;
    font-weight: bold;
}

.join-creator-button:hover {
    background-color: #f0f0f0;
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Subtle hover shadow */
}

.header-button {
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    background-color: #f0f0f0;
    color: #000000;
    font-weight: bold;
}

/* Logo styling */
.solt-logo {
    width: auto;
    height: 15px;
}

.for-you-text::after {
    content: "";
    position: absolute;
    bottom: -5px; /* Adjust distance from text */
    left: 50%;
    transform: translateX(-50%);
    width: 15%;
    height: 2px;
    background-color: #333; /* Adjust color as needed */
}

/* Wrapper to keep title centered and button aligned right */
.for-you-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.for-you-section {
    text-align: center;
}

.for-you-text {
    font-size: 16px;
    font-weight: bold;
    /*border-bottom: 2px solid #333;*/
    /*width: 60%; !* Underline centered *!*/
    margin: 0 auto;
}

.floating-button {
    position: absolute;
    right: 0; /* Align to the right of the wrapper */
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.floating-button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/*!* Floating button style *!*/
/*.floating-button {*/
/*    position: fixed;*/
/*    bottom: 20px;*/
/*    right: 20px;*/
/*    width: 50px;*/
/*    height: 50px;*/
/*    border-radius: 50%;*/
/*    !*background-color: #ffffff;*!*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    cursor: pointer;*/
/*    !*box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);*!*/
/*    z-index: 1000;*/
/*    transition: transform 0.3s ease;*/
/*}*/

/*.floating-button:hover {*/
/*    transform: scale(1.1);*/
/*}*/

/*.floating-button .icon {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    border-radius: 50%;*/
/*}*/


.posts-title {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 15px;
    font-weight: bold;
}

.title-container {
    display: flex;
    align-items: center; /* Vertically align items */
    margin-top: 15px;
    margin-bottom: 15px;
    justify-content: flex-start; /* Align items to the left */
    gap: 10px; /* Add spacing between the logo and title */
}

.arhico-logo {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Make the logo circular */
    object-fit: cover; /* Ensure the logo doesn’t stretch */
    border: 1px solid #ccc; /* Optional: Add a border around the logo */
}

.restaurant-posts-header {
    display: flex;
    align-items: center; /* Ensures text and icon are aligned */
    gap: 8px; /* Space between icon and text */
}

.info-icon {
    width: 16px; /* Adjust icon size */
    height: 16px;
    flex-shrink: 0; /* Prevents shrinking */
}

.restaurant-posts-title {
    font-size: 17px;
    margin-top: 20px;
    /*margin-bottom: 2px;*/
    font-weight: bold;
}

.restaurant-posts-subtitle {
    font-size: 11px;
    /*margin-top: 20px;*/
    margin-bottom: 15px;
    /*font-weight: bold;*/
}


/* Adjust post-card to position relative, allowing absolute positioning of creator-info */
.post-card {
    position: relative;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.post-card:hover {
    transform: scale(1.05);
}

.post-card > img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

/* Wrapper for title and rating */
.post-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Rating display */
.post-rating {
    display: flex;
    align-items: center;
}

.post-rating span {
    color: black !important; /* Adjust color if necessary */
    font-size: 13px !important;
    /*font-weight: bold;*/
}

/* Star icon styling */
.review-star-icon {
    width: 12px; /* Adjust size */
    height: 12px;
    margin-right: 3px;
    margin-left: 5px;
}


.post-info {
    margin-top: 5px;
    padding: 0 0 0 0;
}

/* Title with 1 line limit and ellipsis for overflow */
.post-info h3 {
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

/* Description with 2 lines limit */
.post-info p {
    font-size: 11px;
    color: #666;
    /*margin-top: 5px;*/
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 5px;
    margin-top: 0;
}

/* Description with 2 lines limit */
.post-info span {
    font-size: 11px;
    color: #666;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.no-posts-message {
    text-align: center;
    font-size: 18px;
    color: #888;
    margin-top: 100px;
}

.home-footer {
    text-align: center;
    padding: 20px 0;
    font-size: 14px;
    color: #666;
    width: 100%;
}

.home-footer a {
    color: #000;
    text-decoration: none;
    font-weight: bold;
}

.home-footer a:hover {
    text-decoration: underline;
}

.bottom-sheet-content {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    flex-direction: column; /* Ensure proper stacking of content */
}

.bottom-sheet-content input:focus,
.bottom-sheet-content textarea:focus {
    outline: none;
    border-color: #000; /* Change border color on focus */
}

/* Submit button styling */
.bottom-sheet-content .form-submit-btn {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #000;
    color: #fff;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.bottom-sheet-content .form-submit-btn:hover {
    background-color: #333;
    transform: translateY(-2px);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Subtle hover shadow */
}

/* Text fields */
.bottom-sheet-content input,
.bottom-sheet-content textarea {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    margin-bottom: 15px; /* Spacing between fields */
    border: 1px solid #ddd;
    border-radius: 15px; /* Rounded corners */
    background-color: #fff;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.05);
    transition: border 0.3s ease;
    box-sizing: border-box;
}

/* Labels for text fields */
.bottom-sheet-content label {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
}

.bottom-sheet-content button.form-submit-btn:hover {
    background-color: #333;
    transform: translateY(-2px);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}


.bottom-sheet-content h3 {
    margin-top: 5px;
    margin-bottom: 0;
}

.home-fixed-image-box {
    width: auto;
    height: 40vh;
    /*height: 80%;*/
    object-fit: cover; /* Ensures the image covers the container without stretching */
    border-radius: 8px;
    margin-bottom: 0;
}

.title-info {
    margin-top: 0;
    margin-bottom: 0;
}

.title-info h3 {
    margin-top: 0;
    margin-bottom: 0;
}

.home-fixed-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: auto;
}


.Toastify__toast-container {
    padding: 20px;
}

.Toastify__toast--success {
    /*border: 1px solid #3A9EA5 !important;*/
    border-radius: 15px !important;
    /*background: #F0F9FA !important;*/
}

.Toastify__toast--error {
    /*border: 1px solid #EB5757;*/
    border-radius: 15px !important;
    /*background: #FAE1E2 !important;*/
}

.Toastify__toast {
    border-radius: 20px;
}

.form-input,
.form-textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.form-textarea {
    height: 80px;
    resize: none;
}

.form-submit-btn {
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.form-submit-btn:hover {
    background-color: #333;
}


/* General button styles */
button {
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 10px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Ensure the bottom-sheet-content can handle the new elements */
.bottom-sheet-content label {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
}

.timestamp-info {
    font-size: 11px;
    margin-top: 0;
    color: #666;
}

/* Card for location and description */
.info-card {
    /*background-color: #f8f9fa;*/
    text-align: left;
    padding-bottom: 10px;
    padding-right: 10px;
    border-radius: 10px;
}

/* Flexbox layout for "Where to eat" section */
.location-container {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center vertically */
    width: 100%;
}

/* Location text takes 80% of the width */
.location-text {
    width: 80%;
    font-size: 14px;
    color: #555;
    margin: 0;
    word-wrap: break-word; /* Ensure long text wraps */
}

/* Google Maps icon takes 20% of the width */
.google-maps-icon {
    width: 20%;
    text-align: right;
}

.google-maps-icon img {
    width: 25px;
    height: 25px;
    object-fit: contain; /* Ensure icon doesn't get distorted */
    cursor: pointer;
}


/* Bar style */
.bar {
    height: 1px;
    background-color: #dcdcdc;
    width: 100%;
    border-radius: 4px;
}

.info-card h4 {
    font-size: 15px;
    margin-bottom: 10px;
    color: #333;
}

.info-card p {
    font-size: 14px;
    color: #555;
    margin: 0;
}

.home-overlay-content {
    /*height: 100%;*/
    pointer-events: all; /* Allow interaction with the overlay */
    overflow-y: auto; /* Allow scrolling inside the modal */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    will-change: transform;

    position: relative;
    text-align: left;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

html.overlay-open,
body.overlay-open {
    height: 100vh;
    overflow: hidden;
}

/* Also target specific containers if necessary */
body.overlay-open #root,
body.overlay-open .App {
    overflow: hidden;
}

/* Container for buttons */
.buttons-container {
    margin-top: 20px;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.loading-message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 200px;
}

.loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Ensure it appears on top of other elements */
}


.post-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.creator-info {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0; /* Remove padding */
    background-color: transparent; /* Remove background */
    border-radius: 0; /* Remove border radius */
    color: #666; /* Adjust text color for better visibility */
    margin-top: 5px;
}

.creator-info img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 3px;
}

.creator-info span {
    font-weight: bold;
    color: #666; /* Darker text color */
    font-size: 11px;
}

.creator-info a {
    text-decoration: none;
    color: #666; /* Darker text color */
}


.creator-info-text {
    display: flex;
    align-items: center; /* Center-aligns text under the image */
    font-size: 12px !important; /* Adjust font size as needed */
    text-align: center; /* Center text in each row */
    line-height: 1.2; /* Adjust spacing between rows */
    flex-wrap: wrap; /* Allows text to wrap if it's too long */
    max-width: 85%; /* Set a max width to control text wrapping */
}

/* Creator info in the modal */
.creator-info-modal {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 12px;
    color: #666; /* Gray color for the text */
}

.creator-info-modal .a {
    text-decoration: none;
}

/*.title-container {*/
/*    display: flex;*/
/*    flex-direction: column; !* Align items vertically *!*/
/*    align-items: flex-start; !* Align items to the left *!*/
/*    margin-top: 15px;*/
/*    margin-bottom: 15px; !* Space after the title block *!*/
/*}*/

.restaurant-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    color: #333; /* Adjust to match your theme */
}

.restaurant-subtitle {
    font-size: 14px; /* Smaller font size for subtitle */
    color: #888; /* Grey color for subtitle */
    margin-top: 5px; /* Add space between title and subtitle */
    font-weight: normal;
}


.no-style-link {
    text-decoration: none; /* Removes underline */
    color: inherit; /* Keeps the text color the same as the surrounding text */
    cursor: pointer; /* Ensures it still looks clickable */
}


.creator-profile-image-modal {
    width: 30px;
    height: 30px;
    border-radius: 50%; /* Make the image round */
    object-fit: cover; /* Ensure the image doesn't stretch */
    margin-right: 10px; /* Space between the image and the text */
}

/* Style for the small centered bar above the image */
.modal-bar {
    width: 20%;
    height: 3px;
    background-color: #333; /* Solid bar color */
    border-radius: 2px;
    margin: 0 auto; /* Center horizontally */
    margin-bottom: 10px; /* Space between bar and image */
}


/* Reaction overlay with transparent dark background */
.single-reaction-overlay {
    position: absolute;
    bottom: 4px; /* Adjust to make sure it's half on the image */
    right: 4px; /* Positioned to the right */
    /*background: rgba(0, 0, 0, 0.4); !* Semi-transparent dark background *!*/
    border-radius: 10px; /* Rounded corners */
    /*padding: 5px;*/
    display: flex;
    flex-direction: column; /* Stack icon and number vertically */
    align-items: center; /* Center-aligns both icon and number */
    justify-content: center; /* Center the content */
    /*box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); !* Subtle shadow *!*/
    cursor: pointer; /* Show that it's clickable */
    z-index: 2; /* Make sure it stays on top */
    /*min-width: 45px; !* Ensures the width to accommodate both icon and number *!*/
    height: auto;
}

/* Styling the tastyIcon image */
.reaction-icon {
    width: 20px; /* Adjust the size of the icon */
    height: 20px;
    /*margin-bottom: 5px; !* Space between the icon and the number *!*/
}

/* Reaction number centered directly under the icon */
.reaction-number {
    font-size: 12px; /* Smaller size for the number */
    color: #fff; /* White text for contrast */
    text-align: center; /* Centers the number below the icon */
}

/* Reaction item specific styling to ensure vertical stacking */
.reaction-item {
    display: flex;
    flex-direction: column; /* Stack icon and number vertically */
    align-items: center; /* Center icon and number */
    gap: 5px; /* Space between the icon and the number */
    cursor: pointer;
}

/* Hover effect for reaction item */
.reaction-item:hover {
    transform: scale(1.1); /* Slightly increase size on hover */
    transition: transform 0.3s ease;
}

/* Keyframe for filling and scaling */
@keyframes heartFill {
    0% {
        transform: scale(1);
        filter: grayscale(100%);
    }
    50% {
        transform: scale(1.2); /* Slightly enlarge */
        filter: grayscale(0%);
    }
    100% {
        transform: scale(1);
        filter: grayscale(0%); /* Remove grayscale to make it colored */
    }
}

/* Class to apply the animation */
.fill-animation {
    animation: heartFill 0.6s ease; /* Animation duration and easing */
}

.card-location-container {

    margin-top: 5px;
    display: flex;
    /*justify-content: space-between;*/
    align-items: center; /* Center vertically */
    width: 100%;
}

.card-location-icon {
    width: 20px; /* Adjust size of the icon */
    height: 20px; /* Ensure it’s square */
    /*color: #555; !* If using Font Awesome, you can control the color here *!*/
}

.card-location-text {
    color: #555;
    word-wrap: break-word; /* Ensure long text wraps */
    margin-bottom: 0 !important;
    margin-left: 1% !important;
}

.rating {
    font-size: 12px;
    color: #ffb400; /* Gold color for stars */
}


.profile-image-container {
    position: relative;
}

.dish-title-overlay {
    position: absolute;
    bottom: 0;
    width: 75%;
    /*background: rgba(0, 0, 0, 0.4); !* Semi-transparent background for readability *!*/
    color: #e0e0e0;
    padding: 10px;
    /*text-align: center;*/
}

.dish-title-overlay h3 {
    font-size: 11px;
    margin: 0;
    color: #e0e0e0; /* White text color */
    /*text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); !* Shadow for better contrast *!*/
}


/* Position the search container at the bottom right */
.search-container {
    position: fixed;
    bottom: 20px;
    display: flex;
    align-items: center;
    z-index: 1000;
    width: 50%;
    max-width: 100vw; /* Prevents overflow */
    justify-content: flex-end;
    /*padding-right: 10px;*/
    /*padding-left: 10px;*/
}

/* Style the search icon */
.search-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: transform 0.3s ease;
    margin-right: 10px;
}

/* Style the input field */
.search-input {
    padding: 15px;
    padding-right: 50px; /* Space for the send icon */
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 20px;
    outline: none;
    width: 0;
    opacity: 0;
    transition: width 0.4s ease, opacity 0.4s ease;
    position: relative;
}

/* Show input fully expanded when open */
.search-input.open {
    width: 100%; /* 80% to leave 10% margin on each side */
    opacity: 1;
    margin-right: 10px; /* Space between input and icon */
    border-radius: 20px;
    margin-left: 10px;
}


/* Send icon positioned inside the input field */
.clean-query-icon {
    position: absolute;
    right: 75px; /* Adjust positioning as needed */
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 10;
}

.overlay-title-rating-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 16px;
}

.overlay-post-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    color: #333;
}

.overlay-post-rating {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #555;
}

.overlay-post-rating .overlay-review-star-icon {
    width: 18px;
    height: 18px;
    margin-right: 5px;
}


.show-more-button {
    margin: 20px auto;
    display: block;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.show-more-button:hover {
    background-color: #333;
    transform: translateY(-2px);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}


@media (max-width: 768px) {
    .home-container {
        max-width: 100%;
        padding: 7px;
    }

    .home-fixed-image-box {
        height: auto !important;
    }

    .home-fixed-image {
        width: 100%;
        height: 80%;
        object-fit: cover; /* Ensures the image covers the container without stretching */
        border-radius: 8px;
        backface-visibility: hidden;
        image-rendering: auto;
        will-change: transform;
    }

    .overlay-content {
        max-width: 100% !important;
        width: 100% !important;
    }

    .post-info h3 {
        font-size: 12px;
    }

    .post-info p {
        font-size: 10px;
    }

    .post-info span {
        font-size: 10px;
    }

    .post-rating span {
        font-size: 12px !important;
    }

    .card-location-text {
        font-size: 10px;
        margin-left: 4% !important;
    }

    .card-location-icon {
        width: 15px;
        height: 15px;
    }

    .search-container {
        right: 0;
        width: 100%;
    }
}
