/* TCandPPModal.css */

/* Overlay to dim the background */
.tc-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent black for dimming */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Modal container */
.tc-modal-content {
    background: #ffffff; /* White background */
    border-radius: 12px; /* Rounded corners */
    padding: 20px 30px; /* Spacing inside the modal */
    width: 70%;
    max-width: 500px; /* Maximum width for larger screens */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    animation: fadeIn 0.3s ease-out; /* Animation for modal appearance */
    text-align: center;
}

/* Heading */
.modal-content h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

/* Paragraph text */
.modal-content p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.5;
}

/* Buttons container */
.tc-modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

/* Common button styles */
button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

/* Accept button */
.accept-button {
    background-color: #28a745; /* Green */
    color: white;
}

.accept-button:hover {
    background-color: #218838;
}

.accept-button:disabled {
    background-color: #6c757d; /* Gray for disabled state */
    cursor: not-allowed;
}

/* Decline button */
.decline-button {
    background-color: #dc3545; /* Red */
    color: white;
}

.decline-button:hover {
    background-color: #c82333;
}

.decline-button:disabled {
    background-color: #6c757d; /* Gray for disabled state */
    cursor: not-allowed;
}

/* Animation for modal appearance */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
