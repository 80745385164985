/* FloatingModal.css */

.floating-modal-content {
    overflow: hidden;
    border-radius: 8px;
    margin: 5px 0;
    padding: 12px 7px 20px;
    color: #333;
    text-align: center;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    /*padding: 10px 0;*/
}

.modal-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin: 0 auto;
}

.modal-close-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0;
    padding: 0;
}

.modal-close-icon {
    width: 25px;
    height: 25px;
}

.modal-section {
    margin-top: 20px;
    text-align: left;
}

.modal-section h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #555;
}

.modal-section p,
.modal-section ul {
    font-size: 14px;
    color: #666;
}

.modal-section ul {
    list-style-type: disc;
    margin-left: 20px;
}

.modal-section li {
    margin-bottom: 5px;
}
