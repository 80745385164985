.App {
    text-align: center;
    background-color: #efeeec;
}

body {
    font-family: 'Inter', sans-serif;
    background-color: #efeeec;
}

.App-header {
    /*min-height: 100vh;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*font-size: calc(10px + 2vmin);*/
    color: black;
    position: relative;
    margin-bottom: 5%;
}

.header-section {
    margin-top: 5%;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    justify-content: center;
    text-align: center;
    padding-bottom: 5%;
    /*display: flex;*/
}

.header-section h1 {
    font-size: 3em;
}

.header-section p {
    font-size: 1.5em;
}

.header-section img {
    max-width: 70%;
}

.benefits-section-h1 {
    text-align: center;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 2.4em;
}

.benefits-section-p {
    text-align: center;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 1.2em;
}

/*.logo-container {*/
/*    position: absolute;*/
/*    top: 20px;*/
/*    !*left: 20px;*!*/
/*    left: 15%;*/
/*}*/

.logo-container img {
    height: 25px;
}

.content-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*padding-left: 50px;*/
    /*padding-top: 5px; !* Adjust this value to move the text up or down *!*/
    /*margin-right: 5%;*/
    /*margin-left: 5%;*/

    /*font-size: calc(10px + 2vmin);*/
}

.content-section h1 {
    font-size: 2em;
}

.content-section p {
    font-size: 1.3em;
}

.benefits-section-p a {
    color: black;
    font-weight: bold;
    /*display: block;*/
    text-decoration: none;
}

.phone-image {
    flex: 1;
    max-width: 100%;
    /*padding-right: 50px;*/
    /*text-align-last: center;*/
}

.phone-image img {
    max-width: 80%; /* Adjust this value to change the image size */
}

/*h1 {*/
/*    font-size: 2.5em;*/
/*    margin-bottom: 1em;*/
/*}*/

h3 {
    font-size: 1.5em;
    margin-bottom: 1em;
}

.buttons {
    margin-right: auto;
}

.buttons img {
    width: 300px; /* Adjust this value to make the buttons bigger or smaller */
    height: 80px; /* Adjust this value to make the buttons bigger or smaller */
    object-fit: contain;
}


.buttons a {
    text-decoration: none;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}

.grid-item {
    width: 100%;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
}

.image-container {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
}

.image-container img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
}

.text-container {
    background-color: rgba(255, 255, 255, 0.6); /* This is your white background */
    padding: 10px;
    height: 100%;
}

.product-name {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
    color: black;
}

.location {
    font-size: 16px;
    margin-top: 5px;
    color: black;
}

/* Laptop screen sizes typically start from 992px. Adjust if you have a different convention */
@media (min-width: 992px) {
    .grid-container {
        margin-left: 15%; /* Margin on the left */
        margin-right: 15%; /* Margin on the right */
    }

    .about-us {
        margin-left: 20%; /* Margin on the left */
        margin-right: 20%; /* Margin on the right */
    }

    .blog {
        margin-left: 15%; /* Margin on the left */
        margin-right: 15%; /* Margin on the right */
    }
}

/* Mobile screens */
@media (max-width: 991px) {
    .grid-container {
        margin-left: 5%; /* Margin on the left for mobile */
        margin-right: 5%; /* Margin on the right for mobile */
    }

    .about-us {
        margin-left: 5%; /* Margin on the left for mobile */
        margin-right: 5%; /* Margin on the right for mobile */
    }

    .blog {
        margin-left: 5%; /* Margin on the left */
        margin-right: 5%; /* Margin on the right */
    }
}

.partner-section {
    margin: 10%;
}

.grid-title {
    text-align: center;
    margin: 5%; /* Adjust for desired spacing above and below the title */
    /*margin-left: 10%;*/
}

.grid-title h2 {
    font-size: 2.5em; /* Adjust for desired size */
    font-weight: bold;
}

.about-us h2 {
    font-size: 2.5em; /* Adjust for desired size */
    font-weight: bold;
}


.about-us p {
    font-size: 1.5em; /* Adjust for desired size */
    /*font-weight: bold;*/
}

.blog {
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    position: relative;
    padding-bottom: 2%;
    padding-top: 2%;
    /*padding: 50px 0;*/
}

.blog-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 50px;
}

.blog-content p {
    color: #5d5d5d;
}
.blog-image {
    flex: 1;
    /*padding-right: 50px;*/
}

.blog-image img {
    max-width: 100%;
}


/* Responsive styles for Blog section */
@media (max-width: 768px) {
    .blog {
        flex-direction: column;
    }

    .blog-content {
        flex: none;
        /*padding: 20px;*/
    }

    .blog-image {
        flex: none;
        padding: 20px;
    }

    .blog-image img {
        max-width: 100%;
    }
}

.button {
    display: block; /* Change from inline-block to block for centering */
    margin: 20px auto; /* Centers the button and adds spacing above */
    background-color: black;
    color: white;
    padding: 10px 20px;
    /*max-width: 200px; !* Limit the width of the button *!*/
    text-align: center; /* Ensures text is centered if the button expands */
    border-radius: 15px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #333; /* A slightly lighter black for hover */
    }
}

/*.footer {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    color: #000000;*/
/*    margin-left: 15%;*/
/*    margin-right: 15%;*/
/*    !*border-top: 2px solid rgba(0, 0, 0, 0.7); !* Increased opacity *!*!*/
/*    !*padding-top: 20px;  !* Added padding to create space between border and footer content *!*!*/
/*}*/

/*.footer-left, .footer-right {*/
/*    flex-basis: 20%; !* Giving them equal width *!*/
/*    padding: 0; !* Removed side padding *!*/
/*}*/

/*.footer-left img, .footer-right img {*/
/*    !*max-width: 120px; !* Adjust this value as needed *!*!*/
/*    !*height: auto;*!*/
/*    max-height: 35px;*/
/*}*/

/*.footer-right {*/
/*    text-align: right; !* To make sure items align to the right *!*/
/*}*/


/*.social-links {*/
/*    display: flex;*/
/*    gap: 10px; !* Space between icons *!*/
/*    margin-bottom: 10px; !* Space between the icons and the email *!*/
/*    justify-content: flex-end; !* Aligning the links to the left *!*/
/*}*/


/*.social-links a img {*/
/*    width: 24px; !* Adjust based on desired icon size *!*/
/*    height: 24px; !* Adjust based on desired icon size *!*/
/*}*/

/*!* Style the links *!*/
/*.footer a {*/
/*    color: #000000; !* Blue color for links *!*/
/*    text-decoration: none; !* Removing the underline *!*/
/*}*/

/*.footer a:hover {*/
/*    text-decoration: underline; !* Adding underline on hover for better user experience *!*/
/*}*/

/* Responsive styles for mobile */
@media (max-width: 768px) {
    /*.footer-bar {*/
    /*    margin-left: 0 !important;*/
    /*    margin-right: 0 !important;*/
    /*}*/

    /*.footer {*/
    /*    flex-direction: column;*/
    /*    align-items: center;*/
    /*    margin-left: 5%; !* Adjusted margin for mobile *!*/
    /*    margin-right: 5%; !* Adjusted margin for mobile *!*/
    /*}*/


    /*.footer-left, .footer-right {*/
    /*    text-align: center;*/
    /*    padding: 0; !* Resetting padding for mobile to use the full width *!*/
    /*}*/

    /*.social-links {*/
    /*    justify-content: center;*/
    /*}*/
}

.announcement-banner {
    background-color: red; /* Yellow background for visibility */
    color: white; /* Dark text for contrast */
    text-align: center;
    font-weight: bold;
    padding: 10px 0;
    font-size: 1.2em;
    position: fixed; /* Ensures the banner stays at the top */
    top: 0;
    left: 0;
    width: 100%; /* Ensures full width */
    z-index: 9999; /* High z-index to keep it above other content */
}

.App {
    text-align: center;
    background-color: #efeeec;
    padding-top: 50px; /* Add padding to the top of the app container to account for the banner */
}



.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #fff; /* Optional: You can add your desired background color */
}

.spinner {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Container for logo and login button */
.logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px 10%; /* Adjust padding as needed for spacing */
    margin-left: 5%;
    margin-right: 5%;
}

/* Logo styling */
.logo-container img {
    height: 25px;
}

/* Login button styling */
.login-button {
    /*background-color: #000;*/
    /*color: #fff;*/
    padding: 10px 20px;
    /*border-radius: 15px;*/
    text-decoration: none;
    /*font-weight: bold;*/
    transition: background-color 0.3s ease;
    margin-left: auto; /* Align button to the right within the container */

    background-color: #f0f0f0;
    color: #000000;
    border: 1px solid #666;
    border-radius: 15px;
    font-weight: bold;
}

.login-button:hover {
    background-color: #f0f0f0;
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Subtle hover shadow */
}

/* Responsive styling adjustments */
@media (max-width: 768px) {
    .logo-container {
        flex-direction: row;
        padding: 10px;
    }

    .logo-container img {
        height: 20px;
    }

    .login-button {
        padding: 8px 16px;
        margin-right: 5%;
    }
}


/* Responsive styles */
@media (max-width: 768px) {

    .logo {
        margin-left: 5%;
    }

    .blog {
        padding-bottom: 10% !important;
        padding-top: 10% !important;
        flex-direction: column; /* Stack the text and image vertically */
    }

    .header-section h1 {
        font-size: 1.8em;
    }

    .header-section p {
        font-size: 1em;
    }

    .benefits-section-h1 {
        font-size: 1.7em;
    }

    .benefits-section-p {
        font-size: 0.7em;
    }

    /*.logo-container {*/
    /*    left: 20px*/
    /*}*/

    .blog-content {
        text-align: center;
    }

    .buttons {
        margin-right: auto !important;
        margin-left: auto !important;
        text-align: center !important;
    }

    .phone-image {
        max-width: 100%;
        order: 2; /* Ensure image appears after the text */
        text-align-last: center !important;
    }

    .App-header {
        flex-direction: column;
    }

    .content-section {
        order: 1;
        text-align: center !important;
    }

    .content-section h1 {
        font-size: 1.5em;
    }

    .content-section p {
        font-size: 0.9em;
    }

    h1 {
        font-size: 2.5em;
    }

    .buttons img {
        height: 60px;
        width: 160px; /* Adjust this value for mobile button size */
    }

    .phone-image img {
        max-width: 100% !important; /* Adjust this value for mobile image size */
    }

    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}


/*!* Bar style *!*/
/*.footer-bar {*/
/*    height: 1px;*/
/*    background-color: #dcdcdc;*/
/*    !*width: 70%;*!*/
/*    margin-left: 15%;*/
/*    margin-right: 15%;*/
/*    !*margin: 15px auto; !* Centering the bar with margins *!*!*/
/*    !*margin-bottom: 10px;*!*/
/*    !*margin-top: 10px;*!*/
/*    border-radius: 4px;*/
/*    margin-top: 5%;*/
/*}*/