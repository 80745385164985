/* Container and Box */
.username-setup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*min-height: calc(100vh - 200px); !* Adjust for header and footer height *!*/
    min-height: 65vh;
    background-color: #efeeec; /* Match the background */
    padding: 1rem; /* Add padding to ensure spacing */
}

.username-setup-box {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

/* Heading */
.username-setup-heading {
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
    color: #333;
}

/* Form */
.username-setup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Username Bar */
.username-bar {
    display: flex;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 50px;
    overflow: hidden; /* Ensure rounded corners are applied correctly */
    margin-bottom: 1.5rem;
    width: 100%;
    max-width: 360px;
}

/* Prefix Section */
.username-prefix {
    background-color: #ff3463; /* Dark grey background */
    color: white; /* White text for contrast */
    padding: 1rem 0.3rem 1rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    border-top-left-radius: 50px; /* Ensure the rounded corners */
    border-bottom-left-radius: 50px; /* Ensure the rounded corners */
}

/* Input Section */
.username-input {
    background-color: #f7f7f7; /* Light grey background */
    border: none;
    flex: 1;
    font-size: 1rem;
    padding: 1rem 1rem 1rem 0.2rem;
    outline: none;
    color: #333;
    border-top-right-radius: 50px; /* Ensure the rounded corners */
    border-bottom-right-radius: 50px; /* Ensure the rounded corners */
}

.username-input::placeholder {
    color: #aaa;
}

/* Submit Button */
.username-submit-button {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    margin-top: 10px; /* Slightly reduced space between the status message and the button */
}

.username-submit-button:hover {
    background-color: #333333;
}

/* Subtext */
.username-setup-subtext {
    margin-top: 1rem; /* Adjust space between heading and subtext */
    margin-bottom: 1rem; /* Space before the form */
    font-size: 0.875rem;
    color: #666;
}

/* Info Text */
.username-info-text {
    font-size: 0.75rem;
    color: #666;
    margin-top: -1rem;
    text-align: center;
}

/* Status Message */
.status-message {
    font-size: 0.875rem;
    margin-bottom: 1rem;
    min-height: 20px;
}

/* Loading Spinner */
.loading-spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #000000;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .username-setup-box {
        padding: 1.5rem;
        margin: 0 1.5rem;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }

    .username-setup-heading {
        font-size: 1.125rem;
    }

    .username-bar {
        max-width: 100%;
    }

    .username-submit-button {
        font-size: 0.875rem;
        padding: 0.75rem 1.25rem;
    }
}
