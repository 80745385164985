.blog-post-page * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.blog-post-page {
    font-family: Arial, sans-serif;
    background-color: #f8f8f8;
    min-height: 100vh;
    width: 100%;
    padding: 1rem;
}

/* Navigation container */
.blog-post-page .top-navigation {
    width: 100%;
    background-color: #f8f8f8;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: center;
}

.blog-post-page .nav-content {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding: 0.75rem 0rem;
    border-bottom: 1px solid #ddd;
}

.blog-post-page .solt-logo {
    height: 15px;
}

.blog-post-page .header-buttons {
    display: flex;
    gap: 1rem;
    padding:  0.75rem 0rem;
}

.blog-post-page .header-button, 
.blog-post-page .ios-app-button-link {
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    text-decoration: none;
    padding: 0rem;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.blog-post-page .header-button:hover, 
.blog-post-page .ios-app-button-link:hover {
    background-color: #f0f0f0;
}

/* City Title */
.blog-post-page .city-title {
    text-align: center;
    font-size: 1.75rem;
    font-weight: bold;
    margin: 1rem 0;
    color: #333;
}

/* Posts Container */
.blog-post-page .posts-container {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
}

/* Individual Post Card */
.blog-post-page .post-card {
    background-color: white;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    min-height: 400px;
    height: 750px;
}

.blog-post-page .featured-image {
    width: 400px;
    flex-shrink: 0;
    position: relative;
}

.blog-post-page .featured-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.blog-post-page .post-content {
    flex: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    overflow: visible;
}

.blog-post-page .post-header {
    margin-bottom: 1rem;
}

.blog-post-page .post-title {
    font-size: 1.75rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.blog-post-page .cuisine-tag {
    display: inline-block;
    background-color: #f0f0f0;
    padding: 0.5rem 1rem;
    border-radius: 16px;
    font-size: 0.875rem;
    color: #666;
}

.blog-post-page .post-location {
    color: #666;
    font-size: 1rem;}

.blog-post-page .post-rating {
    color: #FE2B5A;
    font-size: 1rem;
}

.blog-post-page .atmosphere {
    font-size: 1rem;
    color: #666;
    line-height: 1.5;
}

/* Content Sections */
.blog-post-page .content-sections {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.blog-post-page .section {
    margin-bottom: 1rem;
}

.blog-post-page .section-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.75rem;
    border-bottom: 2px solid #F0F0F0;
    padding-bottom: 0.375rem;
}

.blog-post-page .highlights-list {
    list-style: none;
    padding: 0;
}

.blog-post-page .highlights-list li {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: flex-start;
    font-size: 0.875rem;
    line-height: 1.5;
}

.blog-post-page .highlights-list li::before {
    content: "•";
    color: #FE2B5A;
    margin-right: 0.5rem;
}

.blog-post-page .reviews-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.blog-post-page .review-item {
    padding: 0.75rem;
    border-left: 2px solid #FE2B5A;
    background-color: #f9f9f9;
}

.blog-post-page .review-text {
    font-style: italic;
    font-size: 0.875rem;
    color: #666;
    line-height: 1.5;
}
/* Newsletter Section */
.newsletter-section {
    position: relative;
    margin: 3rem auto 0;
    max-width: 1150px;
}
.newsletter-subtitle{
    margin-bottom: 1rem;
}
.newsletter-image {
    width: 100%;
    height: 16rem;
    object-fit: cover;
}

.newsletter-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsletter-content {
    text-align: center;
    color: white;
}

.newsletter-heading {
    font-size: 1.875rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.newsletter-form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.email-input {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    border: none;
    min-width: 300px;
}

.newsletter-button {
    padding: 0.5rem 1.5rem;
    background-color: black;
    color: white;
    border-radius: 9999px;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}
.join-creator-button {
    padding: 8px 16px;
    font-size: 14px;
    align-items: center; /* Align vertically center */
    justify-content: center; /* Center horizontally */
    /*background-color: #000;*/
    /*color: #fff;*/
    /*border: none;*/
    /*border-radius: 8px;*/
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    
    background-color: #f0f0f0;
    color: #000000;
    border: 1px solid #666;
    border-radius: 15px;
    font-weight: bold;
}

.join-creator-button:hover {
    background-color: #f0f0f0;
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Subtle hover shadow */
}
/* Pagination Container */
.pagination {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin: 24px 0; /* space above/below the pagination links */
  }
  
  /* Pagination Links */
  .pagination a {
    display: inline-block;
    padding: 6px 12px;
    color: #333;
    background-color: #eee;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.2s ease;
  }
  
  /* Hover effect */
  .pagination a:hover {
    background-color: #ddd;
  }
  
  /* Active Page Link */
  .pagination a.active {
    background-color: #333;
    color: #fff;
    font-weight: bold;
  }
/* Footer */
.footer {
    background-color: #f8f9fa;
    max-width: 72rem;
    margin: 2rem auto 0;
    padding: 3rem 1rem;
}

.footer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 1rem;
}

.footer-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footer-heading {
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.footer-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 0.875rem;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    margin-top: 2rem;
    flex-wrap: wrap;
}

.footer-links {
    display: flex;
    gap: 1rem;
}

.footer-links a {
    color: inherit;
    text-decoration: none;
}

@media (max-width: 768px) {
    .blog-post-page {
        padding: 0;
    }

    .blog-post-page .top-navigation {
        padding: 0.5rem;
    }

    .blog-post-page .nav-content {
        flex-direction: row;
        align-items: center;
        padding: 0.5rem 0;
    }

    .blog-post-page .header-buttons {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .blog-post-page .city-title {
        font-size: 1.5rem;
        margin: 1.5rem 0;
    }

    .blog-post-page .posts-container {
        padding: 0.5rem;
    }

    .blog-post-page .post-card {
        flex-direction: column;
        height: auto;
    }

    .blog-post-page .featured-image {
        width: 100%;
        height: 300px;
        position: relative;
    }

    .blog-post-page .post-content {
        padding: 1.5rem;
    }

    .blog-post-page .post-title {
        font-size: 1.5rem;
    }

    .newsletter-section {
        margin-top: 2rem;
    }

    .blog-post-page .section-title {
        font-size: 1.125rem;
    }
}

@media (max-width: 380px) {
    .blog-post-page .city-title {
        font-size: 1.25rem;
        margin: 1rem 0;
    }

    .blog-post-page .featured-image {
        height: 180px;
    }

    .blog-post-page .post-content {
        padding: 0.75rem;
    }

    .blog-post-page .post-title {
        font-size: 1.25rem;
    }

    .blog-post-page .section-title {
        font-size: 1rem;
    }
}