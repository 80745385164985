.footer {
    /*width: 100%;*/
    padding: 1.5rem 1rem;
    background-color: #f0f0f0; /* Match the background from the signup page */
    /*border-top: 2px solid #ddd;*/
    margin-left: 15%;
    margin-right: 15%;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.footer-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.footer-message {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 1.5rem; /* Add more space below the tagline */
}

.footer-links {
    display: flex;
    flex-direction: column;
    text-align: right;
    align-items: flex-end;
    font-size: 0.875rem;
    color: #666;
}

.footer-email {
    /*font-size: 1rem;*/
    font-weight: bolder;
}

.footer-links a {
    color: #666;
    margin-bottom: 0.5rem;
    /*display: block;*/
    text-decoration: none;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-bottom {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
    font-size: 0.75rem;
    color: #999;
}

.footer-divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 1.5rem 0;
}

@media (max-width: 768px) {
    .footer {
        margin-left: 0;
        margin-right: 0;
    }
    .footer-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-left,
    .footer-links {
        align-items: center;
        text-align: center;
    }

    .footer-logo {
        height: 20px; /* Smaller logo size for mobile */
    }

    .footer-message,
    .footer-links,
    .footer-bottom {
        font-size: 0.75rem; /* Smaller text on mobile */
    }
}


.profile-footer {
    max-width: 50%;
    margin: 0 auto;
    /*width: inherit;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 1rem;
    /*background-color: #f0f0f0; !* Match the background from the signup page *!*/
    text-align: center; /* Center text */
}

.profile-footer p {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 1rem; /* Space below the text */
}

.footer-button {
    margin-top: 10px;
    width: 40%;
    padding: 10px;
    font-size: 13px;
    background-color: #f0f0f0;
    color: #000000;
    border: 1px solid #666;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-weight: bold;
    margin-right: 10px;
    /*margin-bottom: 1rem; !* Space below the button *!*/
}

.footer-button:hover {
    background-color: #f0f0f0;
}

.footer-logo {
    height: 15px; /* Adjust logo size */
    width: auto;
    cursor: pointer;
}

.footer-logo:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .profile-footer {
        max-width: 95%;
    }

    .footer-button {
        width: 60%;
    }

    .footer-logo {
        height: 15px; /* Smaller logo for mobile */
    }
}
