.blog-page {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
    background-color: #f8f8f8;
    min-height: 100vh;
}

/* Top Navigation */
.blog-page .top-navigation {
    width: 100%;
    background-color: #f8f8f8;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 0.75rem 1rem;
    justify-content: center;
}

.blog-page .nav-content {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.blog-page .solt-logo {
    height: 24px;
}

.blog-page .join-creator-button {
    padding: 8px 16px;
    font-size: 14px;
    display: flex;
    align-items: center; /* Align vertically center */
    justify-content: center; /* Center horizontally */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    background-color: #f0f0f0;
    color: #000000;
    border: 1px solid #666;
    border-radius: 15px;
    font-weight: bold;
    gap: 8px; /* Add space between icon and text */
}

.blog-page .join-creator-button:hover {
    background-color: #333;
}

.blog-page .apple-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 2px;
}

/* Hero Section */
.blog-page .hero {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: 220px;
    padding-left: 1.75rem;
}

.blog-page .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-page .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.4));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 2rem;
}

.blog-page .hero-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    max-width: 800px;
}

.blog-page .hero-description {
    font-size: 1.25rem;
    max-width: 600px;
    line-height: 1.6;
}

/* Blog Content */
.blog-page .blog-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

.blog-page .city-section {
    margin-bottom: 4rem;
}

.blog-page .city-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #111;
    margin-bottom: 2rem;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
}

.blog-page .city-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background: #2563eb;
    border-radius: 2px;
}

.blog-page .section-content {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    align-items: start;
}

.blog-page .main-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.blog-page .food-card {
    background: white;
    border-radius: 16px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 300px 1fr;
    transition: all 0.3s ease;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.blog-page .food-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.blog-page .food-card-image {
    position: relative;
    height: 100%;
}

.blog-page .food-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-page .food-card-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
}

.blog-page .food-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #111;
    margin-bottom: 1rem;
    line-height: 1.4;
}

.blog-page .food-card-rating {
    color: #2563eb;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.blog-page .food-card-rating::before {
    content: '★';
}

.blog-page .food-card-location {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #64748b;
    font-size: 0.9375rem;
    margin-top: auto;
}

/* Sidebar */
.blog-page .sidebar {
    position: sticky;
    top: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.blog-page .sidebar-section {
    background: white;
    border-radius: 10px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: 90%;
}

.blog-page .sidebar-title {
    font-size: 1.25rem;
    font-weight: 700;
    color: #111;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid #e5e7eb;
}

.blog-page .recommendation-list {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    flex: 1;
}

.blog-page .recommendation-item {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #e5e7eb;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.blog-page .recommendation-item:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.blog-page .recommendation-item:hover {
    transform: translateX(4px);
}

.blog-page .recommendation-title {
    font-size: 1rem;
    font-weight: 600;
    color: #111;
    line-height: 1.4;
}

.blog-page .recommendation-subtitle {
    font-size: 0.875rem;
    color: #64748b;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog-page .recommendation-location {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #64748b;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

/* Footer */
.blog-page .footer {
    border-top: 1px solid #eee;
    padding: 3rem 1rem;
}

.blog-page .footer-content {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.blog-page .footer-categories {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 2rem;
}

.blog-page .footer-category {
    margin-bottom: 2rem;
}

.blog-page .footer-category-title {
    font-size: 1.25rem;
    font-weight: 700;
    color: #111;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e2e8f0;
}

.blog-page .footer-category-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 0.5rem;
}

.blog-page .footer-category-link {
    color: #64748b;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1.8;
    transition: color 0.2s;
    display: inline-block;
    cursor: pointer;
}

.blog-page .footer-category-link:hover {
    color: #2563eb;
    text-decoration: underline;
}

@media (max-width: 1024px) {
    .blog-page .footer-categories {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .blog-page .footer-categories {
        grid-template-columns: 1fr;
    }
}

/* Mobile Responsiveness */
@media (max-width: 1024px) {
    .blog-page .section-content {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .blog-page .food-card {
        grid-template-columns: 240px 1fr;
    }

    .blog-page .sidebar {
        position: static;
        margin-top: 2rem;
    }
}

@media (max-width: 768px) {
    .blog-page .city-title {
        font-size: 2rem;
        text-align: left;
    }

    .blog-page .city-title::after {
        left: 0;
        transform: none;
    }

    .blog-page .food-card {
        grid-template-columns: 1fr;
    }

    .blog-page .food-card-image {
        height: 240px;
    }
}

@media (max-width: 480px) {
    .blog-page .city-title {
        font-size: 1.75rem;
    }

    .blog-page .food-card-image {
        height: 200px;
    }

    .blog-page .food-card-title {
        font-size: 1.25rem;
    }
}

/* Handle Notched Phones */
@supports (padding: max(0px)) {
    .blog-page {
        padding-left: max(1rem, env(safe-area-inset-left));
        padding-right: max(1rem, env(safe-area-inset-right));
        padding-bottom: max(1rem, env(safe-area-inset-bottom));
    }
}

/* Featured Section */
.blog-page .featured-section {
    background-color: #f8fafc;
    border-radius: 16px;
    width: 100%;
    margin-bottom: 2rem;
}

.blog-page .featured-title {
    font-size: 1.25rem;
    font-weight: 700;
    color: #111;
    margin-bottom: 1.25rem;
    position: relative;
    padding-left: 1rem;
}

.blog-page .featured-title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.25rem;
    bottom: 0.25rem;
    width: 4px;
    background-color: #2563eb;
    border-radius: 2px;
}

.blog-page .featured-grid-restaurants {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 3.5rem;
    column-gap: 1.25rem;
}

.blog-page .featured-card {
    background-color: white;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.blog-page .featured-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
}

.blog-page .featured-card-title {
    font-size: 1rem;
    font-weight: 700;
    color: #111;
    margin-bottom: 0.5rem;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.8em;
}

.blog-page .featured-card-subtitle {
    font-size: 0.8125rem;
    color: #64748b;
    margin-bottom: 0.75rem;
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
}

.blog-page .featured-card-location {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #64748b;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #f1f5f9;
    padding-bottom: 0.5rem;
}

.blog-page .featured-card-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #2563eb;
    font-size: 0.75rem;
    font-weight: 600;
    margin-top: auto;
}

/* Mobile Responsiveness */
@media (max-width: 1024px) {
    .blog-page .featured-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .blog-page .food-cards-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
    
    .blog-page .featured-grid {
        grid-template-columns: 1fr;
    }
}

/* Mixed Content Layout */
.blog-page .mixed-content {
    margin-bottom: 4rem;
}

.blog-page .content-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #111;
    margin-bottom: 2rem;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
}

.blog-page .content-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background: #2563eb;
    border-radius: 2px;
}

.blog-page .city-tag {
    background-color: #eef2ff;
    color: #4f46e5;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    margin-left: auto;
    flex-shrink: 0;
}

.blog-page .no-content {
    text-align: center;
    padding: 3rem;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.blog-page .no-content p {
    color: #64748b;
    font-size: 1.125rem;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .blog-page .content-title {
        font-size: 2rem;
        text-align: left;
    }

    .blog-page .content-title::after {
        left: 0;
        transform: none;
    }
}

@media (max-width: 480px) {
    .blog-page .content-title {
        font-size: 1.75rem;
    }
}

/* Food Cards Grid */
.blog-page .food-cards-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.blog-page .food-card-small {
    background: white;
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.blog-page .food-card-small:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
}

.blog-page .food-card-small .food-card-image {
    height: 180px;
    width: 100%;
    position: relative;
}

.blog-page .food-card-small .food-card-image::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(to top, rgba(0,0,0,0.1), transparent);
    pointer-events: none;
}

.blog-page .food-card-small .food-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.blog-page .food-card-small .food-card-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
}

.blog-page .food-card-small .food-card-title {
    font-size: 1rem;
    font-weight: 700;
    color: #111;
    margin-bottom: 0.5rem;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 2.8em;
}

.blog-page .food-card-small .food-card-rating {
    color: #2563eb;
    font-weight: 600;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.blog-page .food-card-small .food-card-rating::before {
    content: '★';
}

.blog-page .food-card-small .food-card-location {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    color: #64748b;
    font-size: 0.75rem;
    margin-top: 0.5rem;
    border-top: 1px solid #f1f5f9;
    padding-top: 0.5rem;
}

.blog-page .food-card-small .map-icon {
    color: #94a3b8;
    flex-shrink: 0;
}

/* Pagination */
.blog-page .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2.5rem 0;
    gap: 1.5rem;
}

.blog-page .pagination-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 0.625rem 1.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #111;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.blog-page .pagination-button:hover:not(.disabled) {
    background-color: #f3f4f6;
    border-color: #d1d5db;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-page .pagination-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.blog-page .pagination-info {
    font-size: 0.875rem;
    color: #64748b;
    font-weight: 500;
    background-color: #f8fafc;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: 1px solid #e5e7eb;
}

/* Mobile Responsiveness */
@media (max-width: 1200px) {
    .blog-page .featured-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1024px) {
    .blog-page .food-cards-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .blog-page .food-cards-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
    
    .blog-page .featured-grid {
        grid-template-columns: 1fr;
    }
    
    .blog-page .pagination {
        flex-direction: column;
        gap: 0.75rem;
    }
    
    .blog-page .food-card-small .food-card-image {
        height: 180px;
    }
}

@media (max-width: 640px) {
    .blog-page .food-cards-grid {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .blog-page .food-card-small .food-card-image {
        height: 200px;
    }
}

/* Ensure consistent card heights and alignment */
.blog-page .food-card-small .food-card-content,
.blog-page .featured-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.blog-page .food-card-small .food-card-title,
.blog-page .featured-card-title {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 2.8em;
}

.blog-page .food-card-small .food-card-rating,
.blog-page .featured-card-subtitle {
    margin-bottom: 0.5rem;
}

.blog-page .food-card-small .food-card-location,
.blog-page .featured-card-location {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.75rem;
    color: #64748b;
}

/* Ensure consistent spacing in the grid layouts */
.blog-page .food-cards-grid,
.blog-page .featured-grid {
    row-gap: 1.5rem;
    column-gap: 1.5rem;
}

/* Ensure consistent card shadows and hover effects */
.blog-page .food-card-small,
.blog-page .featured-card,
.blog-page .sidebar-section {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-page .food-card-small:hover,
.blog-page .featured-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
}

/* City List Page Styles */
.blog-page .city-list-section {
    max-width: 1200px;
    width: 100%;
    margin: 3rem auto;
    padding: 0 1rem;
}

.blog-page .city-list-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #111;
    margin-bottom: 2rem;
    text-align: center;
    position: relative;
    padding-bottom: 1rem;
}

.blog-page .city-list-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 4px;
    background: #2563eb;
    border-radius: 2px;
}

.blog-page .city-cards-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.blog-page .city-card {
    background: white;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.blog-page .city-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
}

.blog-page .city-card-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.blog-page .city-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: #111;
    margin-bottom: 1.5rem;
    text-align: center;
    position: relative;
    padding-bottom: 0.75rem;
}

.blog-page .city-card-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 3px;
    background: #2563eb;
    border-radius: 2px;
}

.blog-page .city-links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: auto;
}

.blog-page .city-link {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
    background-color: #f8fafc;
    border-radius: 8px;
    color: #334155;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.2s ease;
    border: 1px solid #e2e8f0;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-family: inherit;
    font-size: inherit;
}

.blog-page .city-link:hover {
    background-color: #f1f5f9;
    color: #2563eb;
    border-color: #cbd5e1;
    transform: translateX(4px);
}

.blog-page .city-link-icon {
    font-size: 1.25rem;
}

/* City Footer */
.blog-page .city-footer {
    background-color: #f8f8f8;
    padding: 3rem 1rem;
}

.blog-page .city-footer-heading {
    font-size: 1.5rem;
    font-weight: 700;
    color: #111;
    margin-bottom: 2rem;
    text-align: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.blog-page .city-footer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.blog-page .city-footer-section h4 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #333;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #e2e8f0;
}

.blog-page .city-footer-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 0.5rem 1rem;
}

.blog-page .city-footer-link {
    color: #64748b;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1.8;
    transition: color 0.2s;
    display: inline-block;
    cursor: pointer;
}

.blog-page .city-footer-link:hover {
    color: #2563eb;
    text-decoration: underline;
}

/* Mobile Responsiveness for City List */
@media (max-width: 1024px) {
    .blog-page .city-cards-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .blog-page .city-list-title {
        font-size: 2rem;
        text-align: left;
    }

    .blog-page .city-list-title::after {
        left: 0;
        transform: none;
    }

    .blog-page .city-cards-grid {
        grid-template-columns: 1fr;
    }

    .blog-page .city-footer-heading {
        text-align: left;
    }

    .blog-page .city-footer-list {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 480px) {
    .blog-page .city-list-title {
        font-size: 1.75rem;
    }

    .blog-page .city-card-title {
        font-size: 1.25rem;
    }
}

.blog-page .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    color: #666;
    font-size: 0.875rem;
}

.blog-page .footer-links {
    display: flex;
    gap: 1.5rem;
}

.blog-page .footer-links a {
    color: #666;
    text-decoration: none;
    transition: color 0.2s;
}

.blog-page .footer-links a:hover {
    color: #2563eb;
}