.blog-restaurant {
    font-family: 'Arial', sans-serif;
    background-color: #f9fafb;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 5px;
}

/* Links */
a {
    color: #6b7280; /* Gray color */
    text-decoration: none;
}

a:hover {
    color: #4b5563; /* Slightly darker gray on hover */
    text-decoration: underline;
}

/* Main Content */
.main-content {
    max-width: 56rem;
    margin: 0 auto;
    padding: 2rem 1rem;
}

.article-meta {
    font-size: 0.875rem;
    color: #4b5563;
    margin-bottom: 0.5rem;
}

.main-heading {
    font-size: 2.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.lead-paragraph {
    color: #4b5563;
    margin-bottom: 2rem;
}

.main-image-container {
    margin-bottom: 2rem;
}

.main-image {
    width: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
}

/* Article Content */
.content-heading {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
}

.content-paragraph {
    color: #374151;
    margin-bottom: 1.5rem;
}
/* Language Switch Buttons */
.lang-switch-button {
    background-color: transparent;
    border: 2px solid #ccc;
    color: #333;
    font-weight: bold;
    padding: 8px 14px;
    margin: 0 6px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
  }
  
  .lang-switch-button:hover {
    background-color: #f0f0f0;
    border-color: #888;
  }
  
  .lang-switch-button.active {
    background-color: #333;
    color: #fff;
    border-color: #333;
  }
  
  .header-buttons {
    display: flex;
    align-items: center;
  }
/* Responsive Grid for Related Articles */
.related-articles .related-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.article-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 16px;
}

.article-card img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
}

/* Newsletter Section */
.newsletter-section {
    position: relative;
    margin-top: 3rem;
}

.newsletter-image {
    width: 100%;
    height: 16rem;
    object-fit: cover;
}

.newsletter-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsletter-content {
    text-align: center;
    color: white;
}

.newsletter-heading {
    font-size: 1.875rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.newsletter-form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.email-input {
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    border: none;
    min-width: 300px;
}

.newsletter-button {
    padding: 0.5rem 1.5rem;
    background-color: black;
    color: white;
    border-radius: 9999px;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

/* Footer */
.footer {
    background-color: #f8f9fa;
    max-width: 72rem;
    margin: 0 auto;
    padding: 3rem 1rem;
}

.footer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 1rem;
}

.footer-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footer-heading {
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.footer-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 0.875rem;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.875rem;
    flex-wrap: wrap;
}

.footer-links {
    display: flex;
    gap: 1rem;
}

.footer-links a {
    color: inherit;
    text-decoration: none;
}

/* Top Navigation */
.blog-restaurant .top-navigation {
    width: 100%;
    background-color: #f8f8f8;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: center;
}

.blog-restaurant .nav-content {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0rem;
    border-bottom: 1px solid #ddd;
}

.blog-restaurant .solt-logo {
    height: 15px;
}

.blog-restaurant .header-buttons {
    display: flex;
    gap: 1rem;
}

.blog-restaurant .header-button,
.blog-restaurant .ios-app-button-link {
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    text-decoration: none;
    padding: 0rem;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.blog-restaurant .header-button:hover,
.blog-restaurant .ios-app-button-link:hover {
    background-color: #f0f0f0;
}

/* Mobile Friendly Adjustments */
@media (max-width: 768px) {
    .lang-switch-button {
        padding: 6px 10px;
        font-size: 14px;
    }
    
    .header-buttons {
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
    }
    
    .main-content {
        padding: 1.5rem 0.75rem;
    }

    .main-heading {
        font-size: 1.75rem;
    }

    .related-articles .related-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .article-grid {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }

    .newsletter-section {
        margin-top: 2rem;
    }

    .email-input {
        min-width: 100%;
    }

    .footer-bottom {
        flex-direction: column;
        text-align: center;
    }

    .footer-links {
        justify-content: center;
        margin-top: 0.5rem;
    }

    .top-navigation {
        padding: 0.5rem;
    }

    .nav-content {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        width: 100%;
    }

    .blog-restaurant .header-buttons {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.5rem;
        padding-right: 1rem;
    }
}