.header {
    width: 100%;
    padding: 1rem;
    background-color: #f0f0f0; /* Match the background from the signup page */
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.header-logo {
    height: 30px; /* Smaller logo size */
    width: auto;
}

@media (max-width: 768px) {
    .header {
        margin-left: 0;
    }
    .header-content {
        flex-direction: column;
        align-items: flex-start;

    }

    .header-logo {
        height: 25px; /* Smaller logo size for mobile */
        margin-bottom: 1rem;
    }
}
