/*!* General Styles *!*/
/*body {*/
/*    font-family: Arial, sans-serif;*/
/*    line-height: 1.6;*/
/*    color: #333;*/
/*}*/

/*!* Container *!*/
/*div {*/
/*    max-width: 800px;*/
/*    margin: 0 auto;*/
/*    padding: 2rem;*/
/*    background-color: #f9f9f9;*/
/*    border-radius: 8px;*/
/*    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);*/
/*}*/

/* Headings */
h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #222;
    /*margin-bottom: 1rem;*/
}

h2 {
    font-size: 2rem;
    font-weight: bold;
    /*color: #444;*/
    /*margin-top: 1.5rem;*/
    /*margin-bottom: 0.5rem;*/
}

h3 {
    font-size: 1.5rem;
    font-weight: 600;
    /*color: #555;*/
    /*margin-top: 1.2rem;*/
    /*margin-bottom: 0.4rem;*/
}

/*!* Links *!*/
/*a {*/
/*    color: #007BFF;*/
/*    text-decoration: none;*/
/*}*/

/*a:hover {*/
/*    text-decoration: underline;*/
/*}*/

/*!* Lists *!*/
/*ul {*/
/*    padding-left: 1.5rem;*/
/*    margin-bottom: 1rem;*/
/*}*/

/*li {*/
/*    margin-bottom: 0.5rem;*/
/*}*/
