/* Reset any unwanted scroll */
html, body {
    overflow-x: hidden; /* Prevent horizontal scrolling */
    margin: 0;
    padding: 0;
    width: 100%;
}

/* Container adjustments */
.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*min-height: calc(100vh - 200px); !* Adjust for header and footer height *!*/
    min-height: 65vh;
    background-color: #efeeec; /* Match the background */
    padding: 1rem; /* Add padding to ensure spacing */
}

/* Box adjustments */
.signup-box {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
    margin: 0 1rem; /* Add margins for smaller screens */
}

/* Heading adjustments */
.signup-heading {
    margin-bottom: 1rem;
    font-size: 1.5rem; /* Smaller text size */
    color: #333;
}

/* Subtext */
.signup-subtext {
    margin-top: 1rem; /* Adjust space between heading and subtext */
    margin-bottom: 1rem; /* Space before the form */
    font-size: 0.875rem;
    color: #666;
}

/* Button adjustments */
.google-signup-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.75rem;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.google-signup-button:hover {
    background-color: #333;
}

.google-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

/* Disclaimer adjustments */
.signup-disclaimer {
    margin-top: 20px;
    font-size: 0.875rem;
    color: #666;
}

.signup-disclaimer a {
    color: #007bff;
    text-decoration: none;
}

.signup-disclaimer a:hover {
    text-decoration: underline;
}

.error-message {
    color:red;
    text-align-last: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .signup-box {
        padding: 1.5rem;
        margin: 0 1.5rem; /* More margin on smaller screens */
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }

    .signup-heading {
        font-size: 1.125rem;
    }

    .google-signup-button {
        font-size: 0.875rem;
    }
}
