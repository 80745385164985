/* Overlay for background dimming */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Align modal at the bottom */
    z-index: 10000000;
    opacity: 1; /* Full opacity for overlay */
    /*transition: opacity 0.8s ease; !* Smooth transition for overlay fade on close *!*/
}

/* The modal content */
.modal-content {
    width: 100%;
    max-width: 50%; /* Set max width to 50% on larger screens */
    background-color: #fff;
    border-radius: 20px 20px 20px 20px; /* Rounded corners at the top */
    padding: 15px;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    overflow-y: auto; /* Scrollable content */
    height: 80vh; /* Fixed height of the modal to 80% of the viewport height */
    transform: translateY(100vh); /* Start off-screen, sliding from the bottom */
    opacity: 1; /* Full opacity, no fade effect on open */
    z-index: 10000001; /* Ensure modal is above other content */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    margin: 0 auto; /* Center the modal */
    /*transition: transform 0.2s ease-out; !* Faster open, no fade transition *!*/
}

@media (min-width: 768px) {
    .modal-overlay {
        align-items: center; /* Center align on desktop */
    }
    .modal-content {
        max-width: 30%; /* Max width of 50% on desktop */
        max-height: 80vh; /* Set height to fit within the viewport */
        height: auto; /* Auto height for desktop */
        /*transform: translateY(0); !* Center the modal on desktop *!*/
        transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    }
}

@media (max-width: 767px) {
    .modal-content {
        max-width: 100%; /* Full width on smaller screens */
        /*height: 80vh; !* Fixed height on mobile *!*/
        border-radius: 20px 20px 0 0; /* Rounded corners at the top for bottom alignment */
        transform: translateY(100vh); /* Start off-screen, sliding from the bottom */
    }
}

/* Opening and closing animations */
.modal-content.opening {
    transform: translateY(0); /* Slide in on open */
    opacity: 1; /* Full opacity when opening, no fade effect */
    transition: transform 0.2s ease-out; /* Smooth opening transition */
}

.modal-content.closing {
    transform: translateY(100%); /* Slide out completely */
    opacity: 1; /* Gradually fade out on close */
    /*transition: transform 0.8s ease-out, opacity 0.6s ease-out; !* Slower closing transition *!*/
}

.modal-content::-webkit-scrollbar {
    /*width: 6px;*/
}

.modal-content::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

/* Prevent interaction with background content */
.modal-overlay {
    pointer-events: none; /* Disable interaction with background */
}

.modal-content {
    pointer-events: auto; /* Re-enable interaction with modal */
}

.modal-overlay.closing {
    opacity: 1; /* Fade out the background overlay when closing */
    /*transition: opacity 0.6s ease; !* Smooth fade out for the overlay *!*/
}

/* Content area should scroll */
.modal-body {
    /*padding: 20px; !* Padding inside the modal body *!*/
    overflow-y: auto; /* Enable scrolling for overflowing content */
    max-height: 100%; /* Content should fill the modal and be scrollable */
}

.modal-body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}
