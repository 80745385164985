/*Profile.css*/

.hidden {
    display: none;
}

/* Skeleton loader */
.skeleton {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-top: 75%; /* Aspect ratio: adjust to match your thumbnail ratio */
    background: #f0f0f0; /* Grey placeholder */
    overflow: hidden;
    border-radius: 8px; /* Optional: matches the image's border radius */
}

/* Skeleton gradient animation */
.skeleton::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -150%;
    width: 150%;
    height: 100%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    animation: skeleton-animation 0.8s ease-in-out infinite;
}

/* Animation */
@keyframes skeleton-animation {
    from {
        left: -150%;
    }
    to {
        left: 100%;
    }
}

/* Hide skeleton once thumbnail loads */
.skeleton.hidden {
    display: none;
}


.profile-container {
    padding: 20px;
    background-color: #efeeec;
    max-width: 50%;
    margin: 0 auto;
    min-height: calc(100vh - 100px);
}

/* Profile Header */
.profile-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Left-align content */
    margin-bottom: 20px;
    position: relative;
    width: 100%;
}

/* Header Top with logo and settings icon aligned */
.header-top {
    display: flex;
    justify-content: space-between; /* This aligns the logo to the left and icon to the right */
    width: 100%; /* Ensures the header spans the full width */
    margin-bottom: 10px;
    margin-top: 5px;
    align-items: center; /* Vertically centers the logo and icon */
}

/* Logo styling */
.solt-logo {
    width: 50px;
    height: auto;
}

.profile-header-content {
    display: flex;
    justify-content: space-between; /* Ensures stats are on the right */
    width: 100%;
    margin-top: 10px;
}

/* Left section: Profile image, name, and username */
.profile-left {
    display: flex;
    align-items: center;
}

/* Profile Image */
.profile-image {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px; /* Space between image and text */
}

/* Profile Info */
.profile-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile-info h1 {
    font-size: 19px;
    margin: 0;
}

.profile-username {
    font-size: 14px;
    color: #666;
}

/* Profile Stats on the right */
.profile-stats {
    display: flex;
    gap: 20px; /* Space between the two stat items */
    align-items: center;
}


.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers number and label */
}

.profile-stats p {
    margin: 0;
    font-size: 14px;
    color: #333;
}

/* Stats Count */
.stat-count {
    font-weight: bold;
    font-size: 16px;
    color: #000;
}

.stat-label {
    font-size: 12px;
    color: #666;
}


/* Profile Header */
/*.profile-header {*/
/*    margin-top: 40px;*/
/*    text-align: center; !* Center everything *!*/
/*    margin-bottom: 20px;*/
/*}*/

.profile-header h1 {
    font-size: 19px;
    margin: 0;
    /*margin-bottom: 10px;*/
}

/* Centering the profile image */
/*.profile-image {*/
/*    width: 75px;*/
/*    height: 75px;*/
/*    border-radius: 50%;*/
/*    object-fit: cover;*/
/*    margin: 0 auto; !* Center the image *!*/
/*    display: block;*/
/*}*/

/* Centering the profile info (name and username) */
/*.profile-info {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center; !* Center name and username *!*/
/*    !*margin-top: 10px;*!*/
/*}*/


/* Name styling */
.profile-name {
    font-size: 20px;
    margin: 0;
}

/* Username styling */
/*.profile-username {*/
/*    font-size: 14px;*/
/*    color: #666;*/
/*    margin: 0;*/
/*    !*margin-top: 4px; !* Space between name and username *!*!*/
/*}*/

.profile-info p {
    margin: 0;
    /*margin-bottom: 15px;*/
    color: #666;
    font-size: 14px;
}

.profile-bio {
    font-size: 14px;
    margin-top: 5px;
}

.profile-stats {
    font-size: 12px;
    color: #666;
    margin-top: 8px;
}

.post-title {
    font-size: 22px;
    margin-top: 20px;
    margin-bottom: 15px;
    font-weight: bold;
}

/* Grid for displaying posts */
.post-grid {
    column-count: 2;
    column-gap: 15px;
    margin: 0 auto;
}

/*.post-grid {*/
/*    display: grid;*/
/*    grid-template-columns: repeat(2, 1fr); !* Two columns *!*/
/*    grid-auto-flow: dense; !* Allow grid items to fill empty spaces *!*/
/*    grid-gap: 15px; !* Space between items *!*/
/*    margin: 0 auto;*/
/*}*/

/*.post-card {*/
/*    border-radius: 10px;*/
/*    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);*/
/*    transition: transform 0.3s ease;*/
/*    overflow: hidden;*/
/*}*/

/*!* Post card styling *!*/
.post-card {
    position: relative;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.post-card:hover {
    transform: scale(1.05);
}

/* Edit button styling */
.post-card .edit-btn {
    position: absolute;
    top: 10px;
    right: 10px; /* Move to the right side of the card */
    padding: 8px 16px;
    font-size: 12px;
    font-weight: bold;
    background-color: #FF9900; /* A different color for visibility */
    color: #fff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.post-card .edit-btn:hover {
    background-color: #cc7a00;
    transform: translateY(-2px); /* Slight hover animation */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Add subtle shadow */
}

.post-card > img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.post-info {
    /*padding: 10px;*/
}

/* Title with 1 line limit and ellipsis for overflow */
.post-info h3 {
    font-size: 16px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

/* Description with 2 lines limit */
.post-info p {
    font-size: 12px;
    color: #666;
    margin-top: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Description with 2 lines limit */
.post-info span {
    font-size: 10px;
    color: #666;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Location container styling */
.profile-card-location-container {
    display: flex;
    align-items: center !important; /* Aligns the icon and text vertically */
    gap: 6px; /* Adds spacing between icon and text */
    margin-top: 5px;
    margin-bottom: 3px;
}

/* Location icon styling */
.profile-card-location-icon {
    width: 15px;
    height: 15px;
    flex-shrink: 0; /* Prevents the icon from resizing */
}

/* Location text styling */
.profile-location-text {
    font-size: 12px;
    color: #666;
    margin: 0 !important;
}


.no-posts-message {
    text-align: center;
    font-size: 18px;
    color: #888;
    margin-top: 100px;
}

.profile-footer {
    text-align: center;
    padding: 20px 0;
    font-size: 14px;
    color: #666;
    width: 100%;
}

.profile-footer a {
    color: #000;
    text-decoration: none;
    font-weight: bold;
}

.profile-footer a:hover {
    text-decoration: underline;
}


/*.bottom-sheet-container {*/
/*    overflow: visible !important;*/
/*}*/

.bottom-sheet-content {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
    /*height: 60vh; !* Restrict height *!*/
    /*overflow: auto; !* Enable vertical scrolling if content overflows *!*/
    /*display: flex;*/

    /*overflow: auto;*/
    /*scroll-behavior: smooth;*/
    /*-webkit-overflow-scrolling: touch;*/
    /*overscroll-behavior: contain;*/
    /*will-change: scroll-position;*/

    flex-direction: column; /* Ensure proper stacking of content */
    /*gap: 15px; !* Optional: add gap between form elements *!*/
}

.bottom-sheet-content input:focus,
.bottom-sheet-content textarea:focus {
    outline: none;
    border-color: #000; /* Change border color on focus */
}

/* Submit button styling */
.bottom-sheet-content .form-submit-btn {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #000;
    color: #fff;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.bottom-sheet-content .form-cancel-btn {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #ffffff;
    color: #000000;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}


.bottom-sheet-content .form-submit-btn:hover {
    background-color: #333;
    transform: translateY(-2px);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Subtle hover shadow */
}


/* Text fields */
.bottom-sheet-content input,
.bottom-sheet-content textarea {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    margin-bottom: 5px !important; /* Spacing between fields */
    border: 1px solid #ddd;
    border-radius: 15px; /* Rounded corners */
    background-color: #fff;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.05);
    transition: border 0.3s ease;
    box-sizing: border-box;
}

/* Labels for text fields */
.bottom-sheet-content label {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
}

/* Submit button */
.bottom-sheet-content button.form-submit-btn {
    padding: 12px 20px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 15px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.bottom-sheet-content button.form-submit-btn:hover {
    background-color: #333;
    transform: translateY(-2px);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.bottom-sheet-content button.form-cancel-btn {
    padding: 12px 20px;
    background-color: #ffffff;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: 1px solid #000;
    border-radius: 15px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-top: 10px;
}

.bottom-sheet-content h3 {
    margin-top: 5px;
    margin-bottom: 0;
}

.profile-fixed-image-box {
    width: 100%;
    height: 40vh !important;
    /*object-fit: contain; !* Ensures the image covers the container without stretching *!*/
    border-radius: 8px;
}

.profile-fixed-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: auto;
}


.Toastify__toast-container {
    padding: 20px;
}

.Toastify__toast--success {
    /*border: 1px solid #3A9EA5 !important;*/
    border-radius: 15px !important;
    /*background: #F0F9FA !important;*/
}

.Toastify__toast--error {
    /*border: 1px solid #EB5757;*/
    border-radius: 15px !important;
    /*background: #FAE1E2 !important;*/
}

.Toastify__toast {
    border-radius: 20px;
}


/* Center the button */
.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    text-decoration: none;
}


/* Google Maps button */
.maps-button {
    /*padding: 10px 20px;*/
    /*background-color: #000;*/
    /*color: #fff;*/
    /*font-size: 14px;*/
    text-align: center;
    text-decoration: none;
    /*border-radius: 5px;*/
    /*cursor: pointer;*/
}

.maps-button:hover {
    background-color: #333;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.form-input,
.form-textarea {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.form-textarea {
    height: 80px;
    resize: none;
}

.form-submit-btn {
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.form-submit-btn:hover {
    background-color: #333;
}

/* Admin controls - Add New Dish and Edit Profile buttons */
.admin-controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

/*.admin-controls button {*/
/*    width: 45%;*/
/*    padding: 10px 20px;*/
/*    background-color: #FF9900; !* A different color for visibility *!*/
/*    color: #fff;*/
/*    font-size: 14px;*/
/*    border: none;*/
/*    border-radius: 15px;*/
/*    cursor: pointer;*/
/*    transition: background-color 0.3s ease, transform 0.3s ease;*/
/*}*/

/* General button styles */
button {
    padding: 10px 20px;
    background-color: #000;
    color: #fff;
    font-size: 14px;
    border: none;
    border-radius: 10px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Add Post button styling */
.admin-controls button {
    width: 48%;
    padding: 12px 24px;  /* Larger padding for a bigger button */
    background-color: #000000; /* A different color for visibility */
    color: white;
    font-size: 15px;  /* Larger font for visibility */
    font-weight: bold;
    border: none;
    border-radius: 15px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    margin-bottom: 20px;
}

.admin-controls button:hover {
    background-color: #000000;  /* Slightly darker green on hover */
    transform: translateY(-2px); /* Slight hover animation */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);  /* Add shadow on hover */
}

/* Disable focus outline for better UI experience */
.admin-controls button:focus {
    outline: none;
}


/* Profile.css */

/* Existing styles... */
/* Image Previews */
.image-previews {
    display: flex;
    justify-content: center; /* Centers the preview */
    margin-bottom: 15px;
}

.image-preview-container {
    position: relative;
    width: 100%; /* Set width to 100% to match container */
    max-width: 200px; /* Restrict maximum width */
    height: 200px; /* Fixed height for preview */
    border-radius: 8px;
    /*overflow: hidden; !* Prevents image overflow *!*/
}

.image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container without stretching */
    border-radius: 8px;
}


.image-preview button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 2px 6px;
    font-size: 12px;
    line-height: 12px;
}

/* Optional: Style the '✕' button */
.image-preview button:hover {
    background-color: rgba(255, 255, 255, 1);
}

/* Ensure the bottom-sheet-content can handle the new elements */
.bottom-sheet-content label {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
}

.bottom-sheet-content input[type="file"] {
    margin-bottom: 15px;
}

.autocomplete-container {
    z-index: 1000000 !important;
    position: relative;
}
.autocomplete-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1000000 !important;
    background-color: white;
    border: 1px solid #ddd;
}

.pac-container {
    z-index: 1000000 !important;
    /*float: left;*/
    overscroll-behavior: contain;
}

/* Style for the title */
.title-info {
    /*margin-top: 10px;*/
    /*text-align: center;*/
}

.timestamp-info {
    font-size: 11px;
    margin-top: 0;
    color: #666;
}

/* Card for location and description */
.info-card {
    /*background-color: #f8f9fa;*/
    text-align: left;
    padding-bottom: 10px;
    padding-right: 10px;
    border-radius: 10px;
    /*margin: 15px 0;*/
    /*box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); !* Subtle shadow for the card *!*/
    /*transition: transform 0.3s ease, box-shadow 0.3s ease;*/
}

/* Bar style */
.bar {
    height: 1px;
    background-color: #dcdcdc;
    width: 100%;
    /*margin: 15px auto; !* Centering the bar with margins *!*/
    margin-bottom: 10px;
    margin-top: 10px;
    border-radius: 4px;
}

.info-card h4 {
    font-size: 15px;
    margin-bottom: 10px;
    color: #333;
}

.info-card p {
    font-size: 14px;
    color: #555;
    margin: 0;
}

/* Hover effect for cards */
.info-card:hover {
    /*transform: translateY(-5px);*/
    /*box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);*/
}

/* Maps button style */
.maps-button {
    display: inline-block;
    padding: 12px 20px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
}

.maps-button:hover {
    background-color: #333;
}

/* Profile.css */
/* Prevent interaction with the background when the overlay is open */
/* Prevent scrolling of background content */
/* Prevent scrolling when overlay is open */
html.overlay-open,
body.overlay-open {
    height: 100%;
    overflow: hidden;
}

/* Also target specific containers if necessary */
body.overlay-open #root,
body.overlay-open .App {
    overflow: hidden;
}
/* Overlay effect */
.overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Change height to cover the full screen */
    background-color: rgba(0, 0, 0, 0.7); /* Dark overlay effect */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    pointer-events: all; /* Allow interaction with the overlay */
    backdrop-filter: blur(1px); /* Adds a blur effect to the content underneath */
    will-change: opacity, transform; /* Optimizes these properties for smoother transitions */
}

/* Content inside the overlay */
.overlay-content {
    background: #ffffff;
    width: 50%;
    max-width: 50%;
    height: 80%; /* Adjust the height as per your need */
    /*padding: 10px;*/
    /*padding-top: 30px;*/
    text-align: center;
    overflow-y: auto; /* Enable vertical scrolling */
    border-radius: 20px 20px 0 0; /* Rounded top corners */
    position: relative;
    /*border: 1px ridge #333; !* Adds a border *!*/
    /*box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);  !* Add shadow on hover *!*/
    will-change: transform;
}

.profile-overlay-content {
    /*height: 100%;*/
    pointer-events: all; /* Allow interaction with the overlay */
    overflow-y: auto; /* Allow scrolling inside the modal */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    will-change: transform;

    position:relative;
    text-align: left;
}


.close-btn {
    background-color: #000;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.close-btn:hover {
    background-color: #333;
}

/* Container for buttons */
.buttons-container {
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*margin-bottom: 20px;*/
    margin-top: 20px;
}

/* Style for the button */
.button-container {
    padding: 12px 24px;
    background-color: #000000;
    color: white;
    font-size: 15px;
    font-weight: bold;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center;
    margin-bottom: 20px;
}

.button-container:hover {
    background-color: #000000;
    transform: translateY(-2px);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Style for links within buttons */
.button-container a {
    color: white;
    text-decoration: none; /* Remove the underline */
    display: inline-block;
    width: 100%;
    height: 100%;
}

.button-container a:hover {
    text-decoration: none; /* Ensure no underline on hover */
}

/* Ensure button focus is smooth */
.button-container:focus {
    outline: none;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.loading-message {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*.post-card {*/
/*    position: relative;*/
/*    border-radius: 10px 10px 0 0;*/
/*    overflow: hidden;*/
/*    transition: transform 0.3s ease;*/
/*}*/

.post-card {
    position: relative;
    /*border-radius: 10px;*/
    overflow: hidden;
    transition: transform 0.3s ease;
}

.profile-image-container {
    position: relative;
}

.post-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    border-radius: 8px;
}

/*!* Reaction overlay with half over the image and half below *!*/
/*.single-reaction-overlay {*/
/*    position: absolute;*/
/*    bottom: 4px; !* Half below the image *!*/
/*    right: 4px; !* Position it to the right *!*/
/*    background: rgba(0, 0, 0, 0.4); !* Semi-transparent dark background *!*/
/*    color: white;*/
/*    !*border: 1px solid #000000; !* Border *!*!*/
/*    padding: 5px 10px;*/
/*    border-radius: 8px; !* Rounded corners for the reaction container *!*/
/*    display: flex;*/
/*    align-items: center;*/
/*    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); !* Subtle shadow *!*/
/*}*/
/* Reaction overlay with transparent dark background */
.single-reaction-overlay {
    position: absolute;
    bottom: 4px; /* Adjust to make sure it's half on the image */
    right: 4px; /* Positioned to the right */
    /*background: rgba(0, 0, 0, 0.4); !* Semi-transparent dark background *!*/
    border-radius: 10px; /* Rounded corners */
    /*padding: 5px;*/
    display: flex;
    flex-direction: column; /* Stack icon and number vertically */
    align-items: center; /* Center-aligns both icon and number */
    justify-content: center; /* Center the content */
    /*box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); !* Subtle shadow *!*/
    cursor: pointer; /* Show that it's clickable */
    z-index: 2; /* Make sure it stays on top */
    /*min-width: 45px; !* Ensures the width to accommodate both icon and number *!*/
    height: auto;
}

/* Styling the tastyIcon image */
.reaction-icon {
    width: 20px; /* Adjust the size of the icon */
    height: 20px;
    /*margin-bottom: 5px; !* Space between the icon and the number *!*/
}

/* Reaction number centered directly under the icon */
.reaction-number {
    font-size: 12px; /* Smaller size for the number */
    color: #fff; /* White text for contrast */
    text-align: center; /* Centers the number below the icon */
}

/* Reaction item specific styling to ensure vertical stacking */
.reaction-item {
    display: flex;
    flex-direction: column; /* Stack icon and number vertically */
    align-items: center; /* Center icon and number */
    gap: 5px; /* Space between the icon and the number */
    cursor: pointer;
}

/* Hover effect for reaction item */
.reaction-item:hover {
    transform: scale(1.1); /* Slightly increase size on hover */
    transition: transform 0.3s ease;
}

/* Keyframe for filling and scaling */
@keyframes heartFill {
    0% {
        transform: scale(1);
        filter: grayscale(100%);
    }
    50% {
        transform: scale(1.2); /* Slightly enlarge */
        filter: grayscale(0%);
    }
    100% {
        transform: scale(1);
        filter: grayscale(0%); /* Remove grayscale to make it colored */
    }
}

/* Class to apply the animation */
.fill-animation {
    animation: heartFill 0.6s ease; /* Animation duration and easing */
}


/* Profile settings icon styling */
.profile-settings-icon {
    cursor: pointer;
    color: black;
    font-size: 30px; /* Set the size of the icon */
}

.profile-settings-icon img {
    width: 25px;
    height: 25px;
}
/* Dropdown menu styling with animation */
.dropdown {
    position: absolute;
    top: 35px; /* Adjust the positioning */
    right: 5px; /* Align to the right */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    opacity: 0; /* Initially hidden */
    transform: translateY(-10px); /* Move it slightly up */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition */
    pointer-events: none; /* Prevent interaction when hidden */
}

/* When the dropdown is visible, change its properties */
.dropdown-active {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Reset its position */
    pointer-events: auto; /* Allow interaction */
}

/* Dropdown option styling */
.dropdown-option {
    padding: 10px;
    color: black; /* Black text for the log-out option */
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
}

.dropdown-option:hover {
    background-color: #f0f0f0; /* Light hover effect */
}

/* Hide the dropdown when it's not active */
.dropdown-hidden {
    display: none;
}

/* Image modal overlay */
.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7); /* Dimmed background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* High z-index to cover everything */
    cursor: pointer; /* Clicking anywhere should close the modal */
}

/* Image modal content */
.image-modal-content {
    position: relative;
    cursor: default; /* Disable cursor change inside the modal */
}

/* Large profile image */
.profile-image-large {
    max-width: 80vw;
    max-height: 80vh;
    object-fit: cover;
    border-radius: 10px; /* Optional: Add rounded corners */
}

.formatted-address-preview {
    font-size: 12px;
    color: #666;
    margin-top: 0;
}

.rating-section-header {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    border-bottom: 2px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

/* Star Rating Container */
.star-rating-container {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Star Icon */
.star-icon {
    font-size: 50px;
    color: #ccc; /* Default star color */
    cursor: pointer;
    transition: color 0.2s ease;
}

/* Selected stars */
.star-icon.selected {
    color: #FFD700; /* Yellow color for selected stars */
    animation: bounce 0.3s ease;
}

@keyframes bounce {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.1); }
}


/* "Would you eat it again?" Container */
.eat-again-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.eat-again-button {
    display: flex;
    flex-direction: row; /* Set items to appear in a row */
    align-items: center; /* Center icon and text vertically */
    background: none;
    border: 1px solid #ddd; /* Subtle border */
    border-radius: 8px; /* Rounded corners */
    /*padding: 10px;*/
    padding-bottom: 0;
    padding-top: 0;
    cursor: pointer;
    /*font-size: 24px;*/
    transition: background-color 0.2s ease, transform 0.2s ease;
    margin: 5px; /* Add margin for spacing */
    font-size: 20px; /* Increase emoji size */
}

.eat-again-button p {
    font-size: 14px;
    color: black;
    /*font-weight: bold;*/
    /*margin-left: 8px; !* Adds space between icon and text *!*/
}

.eat-again-button.selected {
    background-color: #f0f0f0; /* Highlighted background for selected */
    border-color: #333; /* Darker border for emphasis */
    color: #000;
}

.eat-again-button.selected {
    animation: bounce 0.3s ease;
}

@keyframes bounce {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.1); }
}

.eat-again-button.selected[data-choice="yes"] {
    background-color: #4CAF50; /* Green for Yes */
    color: white;
    border-color: #4CAF50;
}

.eat-again-button.selected[data-choice="no"] {
    background-color: #FF4E4E; /* Red for No */
    color: white;
    border-color: #FF4E4E;
}


/* Override the color of <p> inside selected buttons */
.eat-again-button.selected[data-choice="yes"] p,
.eat-again-button.selected[data-choice="no"] p {
    color: white;
}


/* Description title with "(optional)" hint */
.description-title {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.description-optional {
    font-size: 12px;
    color: #999;
    margin-left: 5px;
}

/* Make the description textarea auto-resizable */
.auto-resize-textarea {
    overflow: hidden;
    resize: none; /* Disable manual resizing */
}
/* Category title styling */
.category-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd; /* Optional: line to separate sections */
    padding-bottom: 5px;
}

/* Center-align section title and subtitle */
.section-title {
    font-size: 16px !important;
    font-weight: 400 !important;
    margin-top: 15px;
    text-align: center; /* Center-align the title */
}

.section-subtitle {
    font-size: 0.85rem; /* Smaller font size */
    color: #6c757d; /* Subtle gray color */
    margin-top: -8px; /* Adjust spacing to bring it closer to the title */
    margin-bottom: 10px; /* Space below the subtitle */
    line-height: 1.2; /* Slightly compact line height */
    font-weight: 400; /* Normal font weight for readability */
    text-align: center; /* Center-align the subtitle */
}


textarea {
    font-family: Arial, sans-serif; /* Ensure consistent font */
}
/* Resizing the placeholder text */
textarea::placeholder,
input::placeholder {
    font-family: Arial, sans-serif; /* Ensure consistent font */
    font-size: 14px; /* Adjust font size as needed */
    color: #888; /* Change color for better visibility */
    opacity: 1; /* Ensure full opacity (some browsers default to lower opacity) */
}


@media (max-width: 768px) {
    .profile-container {
        max-width: 100%;
        padding: 10px;
    }

    .post-grid {
        column-count: 2;
    }

    .profile-fixed-image-box {
        height: auto !important;
    }

    .profile-fixed-image {
        width: 100%;
        height: 80%;
        object-fit: cover; /* Ensures the image covers the container without stretching */
        border-radius: 8px;
        backface-visibility: hidden;
        image-rendering: auto;
        will-change: transform;
    }


    /*.overlay {*/
    /*    max-width: 100%;*/
    /*    width: 100%;*/
    /*}*/


    .overlay-content {
        max-width: 100% !important;
        width: 100% !important;
    }
}
